export default {
  close: {
    id: 'collection_points_modal_close',
    defaultMessage: 'Close',
  },
  title: {
    id: 'collection_points_modal_title',
    defaultMessage: 'Select a pickup location',
  },
  searchLabel: {
    id: 'collection_points_modal_search_label',
    defaultMessage: 'Address or ZIP code',
  },
  notFoundErrorTitle: {
    id: 'collection_points_modal_not_found_error_title',
    defaultMessage: 'No pickup locations available',
  },
  notFoundErrorDescriptionDefault: {
    id: 'collection_points_modal_not_found_error_description_default',
    defaultMessage:
      'Check that your address is correct, or you can try another address.',
  },
  notFoundErrorDescriptionFr: {
    id: 'collection_points_modal_not_found_error_description_fr',
    defaultMessage:
      'Check that your address is correct, or you can try another address, but it must be within metropolitan France.',
  },
  submitButton: {
    id: 'collection_points_modal_submit_button',
    defaultMessage: 'Select location',
  },
  submitErrorTitle: {
    id: 'collection_points_modal_submit_error_title',
    defaultMessage: 'Heavens!',
  },
  submitErrorDescription: {
    id: 'collection_points_modal_submit_error_description',
    defaultMessage:
      'Your request was not taken into account. Please try again.',
  },
}
