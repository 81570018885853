export default {
  monday: {
    id: 'collection_points_list_monday',
    defaultMessage: 'Monday',
  },
  tuesday: {
    id: 'collection_points_list_tuesday',
    defaultMessage: 'Tuesday',
  },
  wednesday: {
    id: 'collection_points_list_wednesday',
    defaultMessage: 'Wednesday',
  },
  thursday: {
    id: 'collection_points_list_thursday',
    defaultMessage: 'Thursday',
  },
  friday: {
    id: 'collection_points_list_friday',
    defaultMessage: 'Friday',
  },
  saturday: {
    id: 'collection_points_list_saturday',
    defaultMessage: 'Saturday',
  },
  sunday: {
    id: 'collection_points_list_sunday',
    defaultMessage: 'Sunday',
  },
}
