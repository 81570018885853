<template>
  <div>
    <RevDrawer
      :name="modalName"
      :title="i18n(translations.title)"
      @close="handleClose"
      @open="handleOpen"
    >
      <template #body>
        <!-- eslint-disable vuejs-accessibility/no-autofocus -->
        <AddressAutocomplete
          id="searchQuery"
          v-model="searchQuery"
          autocomplete="off"
          :autofocus="autofocusSearchQuery"
          class="mt-6"
          :feature-code="FeatureCode.WEB_CHECKOUT_COLLECTION_POINTS"
          :label="i18n(translations.searchLabel)"
          type="text"
          @select-item="handleSearchSelectItem"
        />

        <div
          v-if="shouldDisplayImagePlaceholder"
          class="mb-8 mt-[130px] flex max-h-[382px] grow items-center justify-center"
        >
          <RevIllustration
            alt=""
            class="h-[122px] max-h-full w-[150px] max-w-full"
            :height="122"
            src="/img/checkout/deliveryInProgress.svg"
            :width="150"
          />
        </div>

        <CollectionPointsList
          v-if="shouldDisplayCollectionPointsList"
          v-model="selectedCollectionPoint"
          class="mt-4"
          :collection-points
          @select-collection-point="handleCollectionPointListSelect"
        />

        <div
          v-if="shouldDisplayCollectionPointsNotFoundError"
          class="my-8 flex flex-col items-center justify-center text-center"
        >
          <h3 class="heading-3">
            {{ i18n(translations.notFoundErrorTitle) }}
          </h3>
          <span class="body-1 mt-3">
            {{ i18n(notFoundErrorDescription) }}
          </span>
          <RevIllustration
            alt=""
            class="mt-7"
            :height="131"
            src="/img/ParcelBinoculars.svg"
            :width="120"
          >
          </RevIllustration>
        </div>
      </template>

      <template #footer="{ close }" v-if="shouldDisplayFooter">
        <RevButton
          class="my-3"
          data-qa="collection-point-submit-button"
          :disabled="isSubmitButtonDisabled"
          full-width="always"
          :loading="isSubmitting"
          variant="primary"
          @click="handleSubmitButtonClick(close)"
        >
          {{ i18n(translations.submitButton) }}
        </RevButton>
      </template>

      <template #trigger="slotProps">
        <slot name="trigger" v-bind="slotProps" />
      </template>
    </RevDrawer>

    <RevToast
      :close-alternative-text="i18n(translations.close)"
      icon-src="/img/toast/storm.svg"
      :name="submitErrorToastName"
      :opened="shouldDisplaySubmitErrorToast"
      :title="i18n(translations.submitErrorTitle)"
      variant="error"
      @close="shouldDisplaySubmitErrorToast = false"
    >
      {{ i18n(translations.submitErrorDescription) }}
    </RevToast>
  </div>
</template>

<script setup lang="ts">
import { computed, onUnmounted, ref } from 'vue'

import { Country, collectionPointsAPI } from '@backmarket/http-api'
import { postUpdateCollectionPoint } from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import AddressAutocomplete from '@backmarket/nuxt-module-address/AddressAutocomplete.vue'
import { type Address } from '@backmarket/nuxt-module-address/address'
import { FeatureCode } from '@backmarket/nuxt-module-address/featureCode'
import { useInlineFormatAddress } from '@backmarket/nuxt-module-address/useInlineFormatAddress'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevDrawer } from '@ds/components/Drawer'
import { RevIllustration } from '@ds/components/Illustration'
import { RevToast } from '@ds/components/Toast'

import { MODAL_NAMES, TOAST } from '../../../../../../config/constants'
import { useAddressStore } from '../../../../../../stores/addressStore'
import { useCartStore } from '../../../../../../stores/cartStore'
import CollectionPointsList from '../CollectionPointsList/CollectionPointsList.vue'

import translations from './CollectionPointsModal.translations'

type findOptions = { allowNoSelection: boolean }

const NOT_FOUND_ERROR_DESCRIPTIONS: Record<
  Country | string,
  { id: string; defaultMessage: string }
> = {
  [Country.FR]: translations.notFoundErrorDescriptionFr,
  DEFAULT: translations.notFoundErrorDescriptionDefault,
}

const cartStore = useCartStore()
const addressStore = useAddressStore()
const country = useMarketplace().market.countryCode
const i18n = useI18n()
const logger = useLogger()
const tracking = useTracking()

const pendingCollectionPoints: collectionPointsAPI.CollectionPointResponse[] =
  []
const notSelectedCollectionPoint: collectionPointsAPI.CollectionPointResponse =
  {
    address: {
      city: '',
      country: '',
      postalCode: '',
      street: '',
      street2: '',
    },
    distance: 0,
    id: '',
    name: '',
    openingHours: [],
  }

const MODAL_OPEN_TRANSITION_DURATION = 300

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  listingId: string
  shippingId: string
}>()

const address = ref({})
const pending = ref(false)
const autofocusSearchQuery = ref(false)
const autofocusTimeout = ref()
const collectionPointsByAddress = ref<
  Record<string, collectionPointsAPI.CollectionPointResponse[]>
>({})
const isSubmitting = ref(false)
const searchQuery = ref('')
const selectedCollectionPoint = ref(notSelectedCollectionPoint)
const shouldDisplaySubmitErrorToast = ref(false)

const modalName = MODAL_NAMES.CART_COLLECTION_POINTS
const submitErrorToastName = TOAST.CART_COLLECTION_POINTS_SUBMIT_ERROR

const addressKey = computed(() =>
  isEmpty(address.value) ? '' : JSON.stringify(address.value),
)

const collectionPoints = computed(
  () => collectionPointsByAddress.value[addressKey.value] || [],
)

const shouldDisplayImagePlaceholder = computed(() => isEmpty(address.value))

const shouldDisplayCollectionPointsList = computed(
  () =>
    !isEmpty(address.value) &&
    (!isEmpty(collectionPoints.value) || pending.value),
)

const shouldDisplayCollectionPointsNotFoundError = computed(
  () =>
    !isEmpty(address.value) &&
    !pending.value &&
    isEmpty(collectionPoints.value),
)

const notFoundErrorDescription = computed(
  () =>
    NOT_FOUND_ERROR_DESCRIPTIONS[country] ||
    NOT_FOUND_ERROR_DESCRIPTIONS.DEFAULT,
)

const shouldDisplayFooter = computed(
  () => shouldDisplayCollectionPointsList.value,
)

const isSubmitButtonDisabled = computed(
  () =>
    !shouldDisplayCollectionPointsList.value ||
    isEmpty(collectionPoints.value) ||
    !collectionPoints.value.includes(selectedCollectionPoint.value),
)

onUnmounted(() => {
  clearTimeout(autofocusTimeout.value)
})

async function fetchCollectionPoints(
  addressToFetch: Address,
): Promise<collectionPointsAPI.CollectionPointResponse[]> {
  try {
    const payload = await $httpFetch(collectionPointsAPI.getCollectionPoints, {
      queryParams: {
        city: addressToFetch.city,
        country: addressToFetch.country,
        listingId: props.listingId,
        postalCode: addressToFetch.postalCode,
        shippingId: props.shippingId,
        street: addressToFetch.street,
        street2: addressToFetch.street2,
      },
    })

    return payload
  } catch {
    // Display "No results found", and rely on http module for logging
    return []
  }
}

function findSelectedCollectionPoint({ allowNoSelection }: findOptions) {
  const lastCollectionPointId = cartStore.lastCollectionPoint.selectedPoint?.id
  const foundCollectionPoint =
    lastCollectionPointId &&
    collectionPoints.value.find(
      ({ id }: { id: string }) => id === lastCollectionPointId,
    )

  const selectFirstPoint =
    !lastCollectionPointId ||
    (lastCollectionPointId && !foundCollectionPoint && !allowNoSelection)

  return (
    (selectFirstPoint ? collectionPoints.value[0] : foundCollectionPoint) ||
    notSelectedCollectionPoint
  )
}

async function setAddress(newAddress: Address, findOptions: findOptions) {
  address.value = newAddress

  searchQuery.value = isEmpty(newAddress)
    ? ''
    : useInlineFormatAddress(newAddress)

  if (isEmpty(newAddress)) {
    selectedCollectionPoint.value = notSelectedCollectionPoint

    return
  }

  if (
    !Object.keys(collectionPointsByAddress.value).includes(addressKey.value)
  ) {
    // Display pending indicator
    collectionPointsByAddress.value = {
      ...collectionPointsByAddress.value,
      [addressKey.value]: pendingCollectionPoints,
    }

    pending.value = true

    // Fetch and display collection points, and maybe select an option
    const newCollectionPoints = await fetchCollectionPoints(newAddress)

    collectionPointsByAddress.value = {
      ...collectionPointsByAddress.value,
      [addressKey.value]: newCollectionPoints,
    }

    pending.value = false
  }

  selectedCollectionPoint.value = findSelectedCollectionPoint(findOptions)
}

async function handleOpen() {
  if (cartStore.lastCollectionPoint.search?.address) {
    await setAddress(cartStore.lastCollectionPoint.search.address as Address, {
      allowNoSelection: true,
    })
  } else if (addressStore.shipping) {
    await setAddress(addressStore.shipping as Address, {
      allowNoSelection: true,
    })
  }

  const currentCollectionPoint = findSelectedCollectionPoint({
    allowNoSelection: true,
  })

  if (
    !isEmpty(cartStore.lastCollectionPoint.search?.address) &&
    !isEmpty(cartStore.lastCollectionPoint.selectedPoint) &&
    currentCollectionPoint === notSelectedCollectionPoint
  ) {
    logger.info('Could not re-select collection point', {
      type: 'LAST_COLLECTION_POINT_NOT_FOUND',
      address: cartStore.lastCollectionPoint.search.address,
      selectedPoint: cartStore.lastCollectionPoint.selectedPoint,
      collectionPoints: collectionPoints.value,
      owners: ['bot-squad-checkout-front'],
    })
  }

  // Trigger the autofocus after the modal opening transition is complete, in order to avoid a bug on iOS
  // (the keyboard is being displayed and changes the viewport size).
  // Read more: https://backmarket.atlassian.net/browse/CK-1025
  autofocusTimeout.value = setTimeout(() => {
    autofocusSearchQuery.value = isEmpty(searchQuery.value)
  }, MODAL_OPEN_TRANSITION_DURATION + 1)
}

function handleClose() {
  autofocusSearchQuery.value = false
}

async function handleSearchSelectItem(searchedAddress: Address) {
  await setAddress(searchedAddress, { allowNoSelection: false })

  tracking.trackFormSubmit({
    zone: 'collection_points_modal',
    name: `${searchedAddress.postalCode}|${collectionPoints.value.length}`,
  })
}

async function handleCollectionPointListSelect(
  option: collectionPointsAPI.CollectionPointResponse,
  index: number,
) {
  tracking.trackClick({
    zone: 'collection_points_modal_position',
    name: index + 1,
  })
}

async function handleSubmitButtonClick(close: () => void) {
  isSubmitting.value = true
  shouldDisplaySubmitErrorToast.value = false

  try {
    await $httpFetch(postUpdateCollectionPoint, {
      body: {
        search: { address: address.value },
        selectedPoint: selectedCollectionPoint.value,
      },
    })

    await cartStore.fetchCart()

    shouldDisplaySubmitErrorToast.value = false
    close()
  } catch (rootError) {
    shouldDisplaySubmitErrorToast.value = true
  } finally {
    isSubmitting.value = false
  }

  tracking.trackClick({
    zone: 'collection_points_modal',
    name: 'select_location',
  })
}
</script>
