export default {
  descriptionRegular: {
    id: 'cart_shipping_option_description_regular',
    defaultMessage: 'Delivered to your home by {shipper}',
  },
  descriptionCollectionPoint: {
    id: 'cart_shipping_option_description_collection_point',
    defaultMessage: 'Pickup in person at {shipper} locations',
  },
  chooseCollectionPointButton: {
    id: 'cart_shipping_option_choose_collection_point_button',
    defaultMessage: 'Choose a pickup location',
  },
  editCollectionPointButton: {
    id: 'cart_shipping_option_edit_collection_point_button',
    defaultMessage: 'Edit the pickup location',
  },
  mandatoryCollectionPoint: {
    id: 'cart_shipping_option_mandatory_collection_point',
    defaultMessage:
      'Please choose a pickup location or select another shipping method.',
  },
  arrivalDates: {
    id: 'cart_shipping_option_arrival_dates',
    defaultMessage: 'Arrives {earliest}-{latest} • {price}',
  },
  arrivalDatesShort: {
    id: 'checkout_shipping_option_arrival_dates',
    defaultMessage: 'Get it {earliest} - {latest}',
  },
  shippingStandard: {
    id: 'checkout_shipping_option_standard',
    defaultMessage: 'Standard shipping',
  },
  shippingExpress: {
    id: 'checkout_shipping_option_express',
    defaultMessage: 'Express shipping',
  },
  shippingPickup: {
    id: 'checkout_shipping_option_pickup',
    defaultMessage: 'Ship to pickup point',
  },
}
