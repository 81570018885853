<template>
  <ShippingOption
    v-for="choice in choices"
    :key="choice.shippingId"
    :checked="choice.selected"
    class="mt-12 first:mt-0"
    :earliest-arrival-date="choice.earliestArrivalDate"
    :is-collection-point="choice.isCollectionPoint"
    :is-free="choice.price === 0"
    :latest-arrival-date="choice.latestArrivalDate"
    :listing-id
    :price="getFormattedPrice(choice)"
    :shipper="choice.shipperDisplay"
    :shipping-id="choice.shippingId"
    :show-collection-point-error
    @change="checkOption(option, choice)"
  />
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

import { Currency } from '@backmarket/http-api'
import type {
  ShippingChoice as ShippingChoiceType,
  ShippingOption as ShippingOptionType,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import ShippingOption from '../ShippingOption/ShippingOption.vue'

const emit = defineEmits(['select'])

const props = defineProps<{
  listingId: string
  option: ShippingOptionType
  showCollectionPointError: boolean
}>()

const i18n = useI18n()

const choices = ref(props.option.choices)

const getFormattedPrice = (choice: ShippingChoiceType) => {
  if (choice.price === 0) {
    return choice.priceWithCurrency
  }

  return i18n.price({
    amount: `${choice.price}`,
    currency: choice.currency as Currency,
  })
}

const checkOption = async (
  option: ShippingOptionType,
  choice: ShippingChoiceType,
) => {
  choices.value = choices.value.map((c) => {
    return {
      ...c,
      selected: choice.shippingId === c.shippingId,
    }
  })

  emit(
    'select',
    {
      listingId: props.listingId,
      optionType: option.type,
      optionId: choice.shippingId,
    },
    choice,
  )
}

onMounted(() => {
  if (choices.value.every((choice) => !choice.selected)) {
    checkOption(props.option, props.option.choices[0])
  }
})
</script>
