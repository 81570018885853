export default {
  shippingAddressTitle: {
    id: 'funnel_address_confirmation_page_shipping_address_title',
    defaultMessage: 'Confirm your shipping address',
  },
  shippingAddressEditButton: {
    id: 'funnel_address_confirmation_page_shipping_address_edit_button',
    defaultMessage: 'Edit shipping address',
  },
  billingAddressTitle: {
    id: 'funnel_address_confirmation_page_billing_address_title',
    defaultMessage: 'Confirm your billing address',
  },
  billingAddressEditButton: {
    id: 'funnel_address_confirmation_page_billing_address_edit_button',
    defaultMessage: 'Edit billing address',
  },
  collectionPointAddressTitle: {
    id: 'funnel_address_confirmation_page_collection_point_address_title',
    defaultMessage: 'Confirm your shipping details',
  },
  collectionPointAddressEditButton: {
    id: 'funnel_address_confirmation_page_collection_point_address_edit_button',
    defaultMessage: 'Edit shipping details',
  },

  title: {
    id: 'funnel_address_confirmation_page_addresses_title',
    defaultMessage: 'Confirm your addresses',
  },
  subtitleShipping: {
    id: 'funnel_address_confirmation_page_shipping_address_title_short',
    defaultMessage: 'Shipping',
  },
  subtitleBilling: {
    id: 'funnel_address_confirmation_page_billing_address_title_short',
    defaultMessage: 'Billing',
  },
  editButtonShipping: {
    id: 'funnel_address_confirmation_page_shipping_address_edit_button_short',
    defaultMessage: 'Edit',
  },
  editButtonBilling: {
    id: 'funnel_address_confirmation_page_billing_address_edit_button_short',
    defaultMessage: 'Edit',
  },
  sameAddress: {
    id: 'funnel_address_confirmation_page_billing_address_same',
    defaultMessage: '*Same as shipping address',
  },
}
