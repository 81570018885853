<template>
  <RevAccordionList>
    <RevAccordionItem
      v-for="(option, index) in options"
      :key="option.id"
      :class="{ 'pointer-events-none': isSkeleton }"
      :collapsed="isRadioCollapsed(option)"
      :disabled="isSkeleton"
      tabindex="-1"
      @click.prevent="handleListItemClick(option, index)"
    >
      <template #prefix>
        <RevRadio
          v-if="!isSkeleton"
          :id="`collection-point-option-${option.id}`"
          data-qa="collection-point-option"
          :model-value="option.id === modelValue.id"
          :value="option.id"
        />
      </template>

      <template #title>
        <span v-if="!isSkeleton" class="body-1-bold">
          {{ option.name }}
        </span>
        <RevSkeleton v-if="isSkeleton" v-bind="SKELETON_PROPS.NAME">
          {{ i18n(translations.loading) }}
        </RevSkeleton>
      </template>

      <template #description>
        <span v-if="!isSkeleton">
          {{ inlineAddress(option.address) }}
        </span>
        <RevSkeleton
          v-if="isSkeleton"
          v-bind="SKELETON_PROPS.ADDRESS"
          class="mt-2"
        />
      </template>

      <template #image>
        <span v-if="!isSkeleton">
          {{ i18n(translations.distance, { distance: option.distance }) }}
        </span>
        <RevSkeleton v-if="isSkeleton" v-bind="SKELETON_PROPS.DISTANCE" />
      </template>

      <template #body>
        <div v-if="!isSkeleton" class="flex flex-col gap-1">
          <div
            v-for="{ dayOfTheWeek, timeSlots } in option.openingHours"
            :key="dayOfTheWeek"
            class="text-static-default-mid flex justify-between"
          >
            <span class="body-2-bold">
              {{ formatDayOfTheWeek(dayOfTheWeek) }}
            </span>
            <span class="body-2">
              {{ formatTimeSlots(timeSlots) }}
            </span>
          </div>
        </div>
      </template>
    </RevAccordionItem>
  </RevAccordionList>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { collectionPointsAPI } from '@backmarket/http-api'
import { type Address } from '@backmarket/nuxt-module-address/address'
import { useInlineFormatAddress } from '@backmarket/nuxt-module-address/useInlineFormatAddress'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import {
  type CollapsedStates,
  RevAccordionItem,
} from '@ds/components/AccordionItem'
import { RevAccordionList } from '@ds/components/AccordionList'
import { RevRadio } from '@ds/components/Radio'
import { RevSkeleton, type SkeletonShapes } from '@ds/components/Skeleton'

import translations from './CollectionPointsList.translations'

// Ex output: "8:00-12:00 • 14:00-18:00"
const TIME_RANGE_SEPARATOR = '-'
const TIME_SLOTS_SEPARATOR = ' • '

const SKELETON_PROPS_BASE = {
  shape: 'line' as SkeletonShapes,
  variant: 'default',
}

const SKELETON_PROPS = {
  NAME: {
    height: '19px',
    width: '150px',
    ...SKELETON_PROPS_BASE,
  },
  ADDRESS: { height: '18px', width: '200px', ...SKELETON_PROPS_BASE },
  DISTANCE: { height: '18px', width: '35px', ...SKELETON_PROPS_BASE },
}

const props = defineProps<{
  collectionPoints: collectionPointsAPI.CollectionPointResponse[]
  modelValue: collectionPointsAPI.CollectionPointResponse
}>()

const emit = defineEmits(['update:modelValue', 'select-collection-point'])

const i18n = useI18n()

const isSkeleton = computed(() => isEmpty(props.collectionPoints))

const options = computed(() =>
  isSkeleton.value
    ? Array.from({ length: 5 }).map(
        (_, i) =>
          ({
            id: `skeleton-${i}`,
          }) as collectionPointsAPI.CollectionPointResponse,
      )
    : props.collectionPoints,
)

function inlineAddress(address: collectionPointsAPI.CollectionPointAddress) {
  return useInlineFormatAddress(address as Address, { includeCountry: false })
}

function formatDayOfTheWeek(
  dayOfTheWeek: keyof typeof collectionPointsAPI.DAYS_OF_THE_WEEK,
): string {
  return i18n(collectionPointsAPI.DAYS_OF_THE_WEEK[dayOfTheWeek])
}

function formatTimeSlot({
  startTime,
  endTime,
}: collectionPointsAPI.TimeSlot): string {
  return [startTime, endTime].join(TIME_RANGE_SEPARATOR)
}

function formatTimeSlots(timeSlots: collectionPointsAPI.TimeSlot[]): string {
  return isEmpty(timeSlots)
    ? i18n(translations.closed)
    : timeSlots.map(formatTimeSlot).join(TIME_SLOTS_SEPARATOR)
}

function handleListItemClick(
  option: collectionPointsAPI.CollectionPointResponse,
  index: number,
) {
  // Avoid duplicating events:
  // - when clicking on an an already selected option, or
  // - when the inner `<input type="radio">` is checked programmatically.
  // Read more: https://github.com/BackMarket/design-system/pull/2127
  if (option !== props.modelValue) {
    emit('select-collection-point', option, index)
    emit('update:modelValue', option, index)
  }
}

function isRadioCollapsed(
  option: collectionPointsAPI.CollectionPointResponse,
): CollapsedStates {
  return option !== props.modelValue ? 'collapsed' : 'expanded'
}
</script>
