import { createHttpEndpoint } from '../../utils'

import type { GetCollectionPointsResponse } from './collection-points.types'

/**
 * Search for collection points near an address
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/shipping-collection-points-api/definition#tag/backship/operation/FindCollectionPoints}
 */
export const getCollectionPoints =
  createHttpEndpoint<GetCollectionPointsResponse>({
    method: 'GET',
    operationId: 'fetchShippingCollectionPoints',
    path: '/bm/shipping/v1/collection-points',
  })
