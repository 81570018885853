<template>
  <div v-if="showShortAddresses" class="flex flex-col gap-16">
    <h2 class="heading-3">
      {{ i18n(translations.title) }}
    </h2>

    <div class="flex flex-col gap-16 md:flex-row">
      <RevCard class="p-24 md:w-1/2 md:p-32" data-qa="shippingAddressSummary">
        <div class="flex flex-wrap items-center gap-4">
          <IconLocationPinOutlined
            v-if="addressStore.hasCollectionPoint"
            class="shrink-0"
          />
          <IconHome v-else class="shrink-0" />
          <h3 class="body-1 grow">
            {{ i18n(translations.subtitleShipping) }}
          </h3>
          <RevButton
            data-qa="editShippingAddress"
            size="small"
            type="button"
            variant="secondary"
            @click="router.push({ name: CHECKOUT.SHIPPING_ADDRESS })"
          >
            {{ i18n(translations.editButtonShipping) }}
          </RevButton>
        </div>
        <p class="body-1-bold mt-16 md:mt-8">{{ shortShippingAddress }}</p>
      </RevCard>

      <RevCard class="p-24 md:w-1/2 md:p-32" data-qa="billingAddressSummary">
        <div class="flex flex-wrap items-center gap-4">
          <IconBill class="shrink-0" />
          <h3 class="body-1 grow">
            {{ i18n(translations.subtitleBilling) }}
          </h3>
          <RevButton
            data-qa="editBillingAddress"
            size="small"
            type="button"
            variant="secondary"
            @click="router.push({ name: CHECKOUT.BILLING_ADDRESS })"
          >
            {{ i18n(translations.editButtonBilling) }}
          </RevButton>
        </div>
        <p class="body-1-bold mt-16 md:mt-8">{{ shortBillingAddress }}</p>
      </RevCard>
    </div>
  </div>

  <div v-else>
    <h2 class="heading-3">
      {{ i18n(deliveryTitle) }}
    </h2>
    <RevCard
      class="mt-16 flex flex-col items-start gap-5 p-24 lg:flex-row lg:items-center lg:justify-between lg:gap-6"
      data-qa="shippingAddressSummary"
    >
      <Address
        key="shipping-address"
        :address="deliveryAddress"
        :with-details="!addressStore.hasCollectionPoint"
        with-phone
      />

      <RevButton
        data-qa="editShippingAddress"
        :icon="IconEdit"
        size="small"
        type="button"
        variant="secondary"
        @click="router.push({ name: CHECKOUT.SHIPPING_ADDRESS })"
      >
        {{ i18n(deliveryEditButton) }}
      </RevButton>
    </RevCard>

    <h2 class="heading-3 mt-56">
      {{ i18n(translations.billingAddressTitle) }}
    </h2>
    <RevCard
      class="mt-16 flex flex-col items-start gap-5 p-24 lg:flex-row lg:items-center lg:justify-between lg:gap-6"
      data-qa="billingAddressSummary"
    >
      <Address
        key="billing-address"
        :address="addressStore.billing"
        with-details
      />
      <RevButton
        data-qa="editBillingAddress"
        :icon="IconEdit"
        size="small"
        type="button"
        variant="secondary"
        @click="router.push({ name: CHECKOUT.BILLING_ADDRESS })"
      >
        {{ i18n(translations.billingAddressEditButton) }}
      </RevButton>
    </RevCard>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from '#imports'
import { computed } from 'vue'

import { MarketCountryCode } from '@backmarket/http-api'
import type { DeliverCollectionPoint } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import Address from '@backmarket/nuxt-module-address/Address.vue'
import { type Address as AddressType } from '@backmarket/nuxt-module-address/address'
import { useFormatAddress } from '@backmarket/nuxt-module-address/useFormatAddress'
import { useFormatPhone } from '@backmarket/nuxt-module-address/useFormatPhone'
import { getFullName } from '@backmarket/nuxt-module-address/utils/name/getFullName'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { IconBill } from '@ds/icons/IconBill'
import { IconEdit } from '@ds/icons/IconEdit'
import { IconHome } from '@ds/icons/IconHome'
import { IconLocationPinOutlined } from '@ds/icons/IconLocationPinOutlined'

import { CHECKOUT } from '../../../../routes-names'
import { useAddressStore } from '../../../../stores/addressStore'

import translations from './Addresses.translations'

const i18n = useI18n()
const router = useRouter()
const addressStore = useAddressStore()
const experiments = useExperiments()
const country = useMarketplace().market.countryCode

const deliveryAddress = computed(() => {
  // Since we are checking if it has collectionPoint this can't be undefined
  return addressStore.hasCollectionPoint
    ? (addressStore.collectionPoint as DeliverCollectionPoint)
    : addressStore.shipping
})

const deliveryTitle = computed(() => {
  return addressStore.hasCollectionPoint
    ? translations.collectionPointAddressTitle
    : translations.shippingAddressTitle
})

const deliveryEditButton = computed(() => {
  return addressStore.hasCollectionPoint
    ? translations.collectionPointAddressEditButton
    : translations.shippingAddressEditButton
})

const showShortAddresses = computed(() => {
  return (
    experiments['experiment.checkoutConfirmAddressDisplay'] ===
    'shorterConfirmAddress'
  )
})

// Q: should we (and can we) move those to some util file or to the AddressStore?
const shouldIncludeCountry = computed(() => {
  return [MarketCountryCode.US, MarketCountryCode.AU].includes(country)
})

const shortShippingAddress = computed(() => {
  if (!addressStore.isShippingAddressComplete) {
    return null
  }

  const address = addressStore.hasCollectionPoint
    ? [
        getFullName(
          addressStore.shipping.country,
          addressStore.shipping.firstName ?? '',
          addressStore.shipping.lastName ?? '',
        ),
      ]
    : useFormatAddress(addressStore.shipping as AddressType, {
        includeCountry: shouldIncludeCountry.value,
        includeName: true,
      })

  return [
    ...address,
    useFormatPhone(addressStore.shipping as AddressType),
  ].join(', ')
})

const shortBillingAddress = computed(() => {
  if (!addressStore.isBillingAddressComplete) {
    return null
  }

  if (
    addressStore.areShippingAndBillingTheSame &&
    !addressStore.hasCollectionPoint
  ) {
    return i18n(translations.sameAddress)
  }

  return [
    ...useFormatAddress(addressStore.billing as AddressType, {
      includeCountry: shouldIncludeCountry.value,
      includeName: true,
    }),
    useFormatPhone(addressStore.billing as AddressType),
  ].join(', ')
})
</script>
