export default {
  submitButton: {
    id: 'funnel_address_confirmation_page_submit_button',
    defaultMessage: 'Next',
  },
  submitButtonABTest: {
    id: 'funnel_address_confirmation_page_submit_button_abtest',
    defaultMessage: 'Continue to payment',
  },
  submitSwapInfoTitle: {
    id: 'funnel_address_confirmation_page_swap_submit_toast_error_title',
    defaultMessage: 'Oups',
  },
  submitSwapInfoError: {
    id: 'funnel_address_confirmation_page_swap_submit_toast_error_message',
    defaultMessage: 'Something went wrong. Please try again.',
  },
  toastClose: {
    id: 'funnel_address_confirmation_page_toast_close_alternative_text',
    defaultMessage: 'Close',
  },
  userInformationTitle: {
    id: 'funnel_address_confirmation_user_information_title',
    defaultMessage: 'Personal information',
  },
  missingData: {
    id: 'funnel_address_confirmation_page_missing_data',
    defaultMessage: 'You need to fill all the required fields.',
  },
}
