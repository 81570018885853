import type { Country } from '../../standards'

import translations from './collection-points.translations'

export const DAYS_OF_THE_WEEK = {
  1: translations.monday,
  2: translations.tuesday,
  3: translations.wednesday,
  4: translations.thursday,
  5: translations.friday,
  6: translations.saturday,
  7: translations.sunday,
} as const

export type TimeSlot = { startTime: string; endTime: string }

export type CollectionPointAddress = {
  street: string
  street2: string | null
  postalCode: string
  country: Country | string
  city: string
}

export type CollectionPointResponse = {
  address: CollectionPointAddress
  distance: number
  id: string
  name: string
  openingHours: Array<{
    dayOfTheWeek: keyof typeof DAYS_OF_THE_WEEK
    timeSlots: TimeSlot[]
  }>
}

export type GetCollectionPointsResponse = CollectionPointResponse[]
